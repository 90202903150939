export enum GeneralMessages {
    
    MSG_SERV_NOT_AVAILABLE = 'Servicio no disponible',
    MSG_NO_RESULTS_FOUND = 'No se encontraron resultados',
    MSG_CONNECT_ERROR = 'Error de conexión',
    MSG_REQUIRED_FIELDS = 'Los campos de Login son obligarios. \n Favor de a completar',
    MSG_REQUIRED_FIELDS_STORE = 'Favor de a completar los campos requeridos',
    MSG_SELECT_ONE_OR_MORE_SERVICE = "Seleccione uno o mas Servicios para continuar",
    MSG_ERR_FORM_DETAIL = "Es necesario corregir los errores para continuar",
    MSG_SELECT_PAYMENT_METHOD = "Seleccione un método de pago",
    MSG_MAIL_REQUIRED = "La dirección de correo es requerida",
    MSG_VALID_EMAIL_ADDRESS = "Escriba una dirección de correo valida",
    MSG_ERROR_PAYMENT = "Ocurrio un error al realizar el pago",
    MSG_ERROR_BILL_CHANGED_IN_PAYMENT = "La factura seleccionada fue modificada recientemente.\nPor favor reintente el pago.",
    MSG_APPROVED = "Aprobado",
    MSG_NOT_APPROVED = "No Aprobado",
    MSG_IN_PROGRESS = "Su pago esta en proceso",
    MSG_PAYMENT_CARD = "Tarjeta Bancaria",
    MSG_PAYMENT_PSE = "Pago por PSE",
    MSG_ERR_RECEIPT_UNLOAD = "El recibo no pudo ser descargado",
    MSG_FULL_NAME_REQUIRED = "El nombre del tarjetahabiente es requerido",
    MSG_CARD_REQUIRED = "El número tarjeta es requerido",
    MSG_CARD_VALID = "La tarjeta es invalida",
    MSG_EXPIRE_MONTH_REQUIRED = "El mes es requerido",
    MSG_EXPIRE_MONTH_VALID = "El mes es inválido",
    MSG_EXPIRE_YEAR_REQUIRED = "El año es requerido",
    MSG_EXPIRE_YEAR_VALID = "El año es inválido",
    MSG_CVV_REQUIRED = "El código es requerido",
    MSG_DUES_REQUIRED = "Las cuotas son requeridas",
    MSG_MOVIL_REQUIRED = "El teléfono es requerido",
    MSG_BAK_REQUIRED = "El banco es requerido",
    MSG_PERSON_REQUIRED = "El tipo de persona es requerido",
    MSG_DOCUMENT_REQUIRED = "El tipo de documento es requerido",
    MSG_ID_NUMBER_REQUIRED = "El número de identificación es requerido",
    MSG_NAME_REQUIRED = "El nombre completo es requerido",
    MSG_MOVIL_PHONE_REQUIRED = "El número móvil es requerido",
    MSG_DESCRIPTION_REQUIRED = "La descripción es requerida",
    MSG_ADDRESS_REQUIRED = "La dirección es requerida",
    MSG_ERROR_INVALID_STATUS_FOR_PAYMENT = "La factura tiene un estatus invalido para procesar el pago"

}