import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SettingsService } from '../shared/settings.service'

let username = 'openpay';
let password = 'openpaypass';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Authorization': 'Basic ' +  btoa(username+':'+password)
  })
}


@Injectable({
  providedIn: 'root'
})
export class ValidateBinService {

  constructor(protected http: HttpClient, protected settingsService: SettingsService) { }

  getTypeCard(bin: string) {  
    return this.http.get(`${this.settingsService.settings.apiUrl}v1/cards/validate-bin?bin=${bin}`, httpOptions);

  }  


}