import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { DataValidationRequest } from '../../models/data-validation-request.models';
import {Observable} from 'rxjs'
import { SettingsService } from '../shared/settings.service'

let username = 'openpay';
let password = 'openpaypass';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Authorization': 'Basic ' +  btoa(username+':'+password)
  })
}

@Injectable({
  providedIn: 'root'
})
export class PaymentDetailService {

  constructor(protected http: HttpClient, protected settingsService: SettingsService) { }


  getDetailByMerchantAndProject(merchantId: string, projectId: string, request: DataValidationRequest){
    return this.http.post(`${this.settingsService.settings.restUrlSvc}project/file/${merchantId}/${projectId}`,request,httpOptions)    
  }

  getTransactionStatus(merchantId: string, projectId: string,transaction: string) {
    return this.http.get(`${this.settingsService.settings.restUrlPay}${merchantId}/${projectId}/transaction/${transaction}`,httpOptions);
  }

  downloadReceipt(merchantId: string, projectId: string,reference: string): Observable<any> {
    let receipOptions = httpOptions;
    receipOptions['observe'] = 'response';
    receipOptions['responseType'] = 'blob'
    return this.http.get(`${this.settingsService.settings.restUrlSvc}project/receipt/${merchantId}/${projectId}/${reference}`, 
    receipOptions);
  }
  
}
