import { Directive, HostListener, ElementRef, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[credit-card]'
})
export class CreditCardDirective {
  @Output() ngModelChange:EventEmitter<any> = new EventEmitter()
  
  @HostListener('input', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    let trimmed = input.value.replace(/\s+/g, '');
    if (trimmed.length > 16) {
      trimmed = trimmed.substr(0, 16);
    }

    if (trimmed.length !== 15) {
      let numbers = [];
      for (let i = 0; i < trimmed.length; i += 4) {
        numbers.push(trimmed.substr(i, 4));
      }
      input.value = numbers.join(' ');
    }

    if (trimmed.length === 15) {
      let numbers = [];
      for (let i = 0; i < trimmed.length; i += 5) {
        numbers.push(trimmed.substr(i, 5));
      }
      input.value = numbers.join(' ');
    }
    this.ngModelChange.emit(input.value);
  }

}