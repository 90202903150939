import {AfterViewChecked, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import {FormControl,FormBuilder,FormGroup,Validators, NgForm} from '@angular/forms';
import { MatDialog  } from '@angular/material/dialog';
import { TablePaymentDetail } from 'src/app/models/table-payment-detail.models';
import { ModalPaymentMethodsComponent } from '../../modals/modal-payment-methods/modal-payment-methods.component';
import { Router, ActivatedRoute } from '@angular/router';
import { DataValidationRequest } from 'src/app/models/data-validation-request.models';
import { PaymentDetailService } from 'src/app/services/payment-detail/payment-detail.service';
import { StyleSite } from 'src/app/utils/style-site';
import { Alert } from 'src/app/alerts/alert';
import { GeneralMessages } from 'src/app/commons/general-messages';
import { DataPayRequest } from 'src/app/models/data-pay-request-models';
import * as fileSaver from 'file-saver'
import Utils from 'src/app/utils/utils';
import { AuthGuardService } from 'src/app/services/auth-guard/auth-guard.service';




declare var $: any;
declare var OpenPay: any;

@Component({
  selector: 'app-payment-detail',
  templateUrl: './payment-detail.component.html',
  styleUrls: ['./payment-detail.component.scss']
})
export class PaymentDetailComponent implements OnInit, AfterViewChecked {


  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort,{static: true}) sort: MatSort;
  @ViewChild('frmDetail',{static: true}) frmDetail: NgForm;
  merchantId: string;
  projectId: string;
  transactionId: string;
  responseTable: any[];
  displayedColumns  = [];
  fieldTitle = [];
  totalPayment : number;
  totalIva: number;
  request: DataValidationRequest;
  logoPath: string;
  colorRadio: any;
  styleSite: StyleSite;
  alert = new  Alert();
  msg = GeneralMessages;
  dataSource = new MatTableDataSource<TablePaymentDetail>(this.responseTable);
  checkboxSelect = [];
  rowDetailSelected: any[] = [];
  publicId: string
  dataPayRequest : DataPayRequest;
  deviceSessionId: any;
  portalColor: String;
  /*Bandera que indica si permite pagos parciales.*/
  allowParcialPaid: Boolean = true;
  /** Columnas con comportamiento especial */
  colsEspecialBehavior = ['id','AMOUNT','IVA', 'AMOUNT_TO_PAID','RECAUDO_INTERNAL_PREVIOUS_PAYMENT', 'RECORD_KEY'];
  header: string;

  constructor(
    protected formBuilder: FormBuilder,
    private dialog: MatDialog,
    protected router: Router,
    protected route: ActivatedRoute,
    private paymentDetailService: PaymentDetailService,
    private authService : AuthGuardService,
    private cdr: ChangeDetectorRef
  ) {  }



  ngOnInit() {
    var project = JSON.parse(sessionStorage.getItem("project"));
    this.settingTheme();
    this.merchantId = project.merchantId;
    this.projectId = project.projectId;
    this.transactionId = this.route.snapshot.queryParams.id;
    this.logoPath = sessionStorage.getItem('logoPath');
    this.styleSite = JSON.parse(sessionStorage.getItem('styleSite'));
    this.initDetail();
    this.dataPayRequest = new DataPayRequest;
    if(this.transactionId) {
      this.paymentDetailService.getTransactionStatus(this.merchantId,this.projectId,this.transactionId)
      .subscribe(
        (dataTransaction: any) => {
          this.modalPaymentMethods(dataTransaction);
        },(error) => {
          if (error.error !== undefined && error.error.description !== undefined) {
            this.alert.alertError(error.error.description);
          }else{
            this.alert.alertWarning(this.msg.MSG_NO_RESULTS_FOUND);
          }
        });
    }
  }

  /**
   * Inicializa la informacion del detalle.
   */
  initDetail() {
    console.log("initDetail");
    this.totalPayment = 0;
    this.totalIva = 0;
    this.rowDetailSelected = [];
    this.getDetail(this.merchantId,this.projectId);
  }

  onAmountChange(checked, row, element) {
    if(checked){
      row.selected = true;
      this.rowDetailSelected.push(row);
      //$('#lbl_' + element).css("color", sessionStorage.getItem('portalColor'));
    } else {
      row.selected = false;
      this.removeRow(row);
      row.AMOUNT_TO_PAID = row.AMOUNT;
      //$('#lbl_' + element).css("color", '#666');
    }

    this.dataPayRequest.bills_paid = this.rowDetailSelected;
    this.updateAmount();
  }

  onUnloadReceiptClick(row:any) {
    this.paymentDetailService.downloadReceipt(this.merchantId, this.projectId, row.REFERENCE_PAYMENT)
      .subscribe((res) => {
        let data = {
                     image: new Blob([res.body], {type: res.headers.get('Content-Type')}),
                     filename: res.headers.get('content-disposition').split(';')[1].split('=')[1].replace(/\"/g, '')
                  };
        fileSaver.saveAs(data.image, data.filename);
    }, (error) => {
      this.alert.alertError(this.msg.MSG_ERR_RECEIPT_UNLOAD);
    });
  }

  /**
   * Actualiza la cantidad a pagar, de acuerdo a los renglones seleccionados.
   */
  updateAmount() {
    $('.mat-form-field-infix').css("border-top", "none");
    this.totalPayment = 0;
    this.totalIva = 0;
    this.rowDetailSelected.forEach(function(row) {
      let amount = (row.AMOUNT_TO_PAID == null) ? Number(0) : Number(row.AMOUNT_TO_PAID);
      let iva = (row.IVA == null) ? Number(0) : Number(row.IVA);
      this.totalPayment += Number(amount);
      this.totalIva += Number(iva);
    }, this);
    this.dataPayRequest.amount = Number(this.totalPayment);
    this.dataPayRequest.iva = Number(this.totalIva);
  }

  removeRow(row){
    var newRow: any[] = [];
    for(var i=0; i<this.rowDetailSelected.length; i++){
      if(this.rowDetailSelected[i] !== row){
        newRow.push(this.rowDetailSelected[i]);
      }
    }
    this.rowDetailSelected = [];
    this.rowDetailSelected = newRow;
  }

  /**
   * Indica si una columna esta en la lista de columnas con comportamiento especial
   * @param columnName Nombre de la columna.
   */
  colIsSpecialBehavior(columnName:any):boolean {
    return this.colsEspecialBehavior.includes(columnName);
  }

  getDetail(merchantId: string, projectId: string) {
    this.request = JSON.parse(sessionStorage.getItem('dataLoginShared'));
    console.log("getDetail request:", this.request);
    this.paymentDetailService.getDetailByMerchantAndProject(merchantId, projectId, this.request)
      .subscribe(
      (data: any) => {
        console.log("getDetail data.tableData: ", data.tableData);
        this.responseTable = data.tableData;
        this.dataSource = new MatTableDataSource<any>(data.tableData);
        this.dataSource.sort = this.sort;
        console.log("getDetail data.fieldTitle: ",  data.fieldTitle);
        this.fieldTitle = data.fieldTitle;
        this.dataSource.paginator = this.paginator;
        this.publicId = data.publicKey;
        this.dataPayRequest.data = JSON.parse(sessionStorage.getItem('dataLoginShared')).data;
        this.dataPayRequest.project_name = data.projectName;
        this.dataPayRequest.projectPublicId =  projectId;
        this.allowParcialPaid = data.allowParcialPaid;
        console.log("getDetail data.displayedColumns: ", data.displayedColumns);
        this.displayedColumns  = this.ajustaColumnas(data.displayedColumns);
        this.displayedColumns = this.displayedColumns.filter(cd=>cd.show).map(cd=>cd.def);
        this.header = data.header;
      },(error) => {
        this.alert.alertWarning(this.msg.MSG_NO_RESULTS_FOUND);
      }
      );
  }

  /**
   * Ajusta las colummnas recibidas del back, para agregar informacion correspondiente a la vista.
   * p.e. columnas a ocultar o mostrar
   * @param columnas
   */
  ajustaColumnas(columnas:any[]):any[] {
    var retColumns:any[] = [];
    columnas.forEach(function (column) {
      let showCol:boolean = true;
      if (column == 'AMOUNT_TO_PAID') {
        showCol = this.allowParcialPaid;
        $('.mat-form-field-infix').css("border-top", "none");
      }
      if(column == 'RECORD_KEY'){
        showCol = false;
      }
      retColumns.push({def:column, show:showCol});
    }, this);
    return retColumns;
  }

  /**
   * Obtiene la lista de columnas a mostrar
   */
  getDisplayedColumns():string[] {
    return this.displayedColumns.filter(cd=>cd.show).map(cd=>cd.def);
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }


  modalPaymentMethods(dataTransaction: any): void {
    if (this.frmDetail.invalid) {
      this.alert.alertWarning(this.msg.MSG_ERR_FORM_DETAIL);
      return;
    }

    if(this.rowDetailSelected.length <= 0 && !this.transactionId) {
      this.alert.alertWarning(this.msg.MSG_SELECT_ONE_OR_MORE_SERVICE);
    } else {
      let dialogRef = this.dialog.open(ModalPaymentMethodsComponent, {
        panelClass : 'modal-payment',
        data: {
          merchantId: this.merchantId,
          projectId: this.projectId,
          publicId: this.publicId,
          transactionId: this.transactionId,
          dataPayRequest: this.dataPayRequest,
          transaction: dataTransaction
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log("Dialog result:", result);
        this.transactionId = '';
        if (result != undefined && result.paymentCompleted) {
          this.initDetail();
        }
      });
    }

  }

  settingTheme(){
    var portalColor = sessionStorage.getItem('portalColor');
    this.portalColor = portalColor;
    $('.btn-next').css("background-color", portalColor);
    $('.mat-form-field ng-tns-c3-0 .mat-primary .mat-form-field-type-mat-input .mat-form-field-appearance-legacy .mat-form-field-can-float .mat-form-field-has-label .mat-form-field-hide-placeholder .mat-form-field-should-float .mat-focused').css("color", portalColor);
  }


  logoutPortal(){
    Utils.cleanSession();
    this.authService.logoutPortal(this.merchantId, this.projectId);
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  roundTotalValue(value: number) : number {
    return Math.ceil(value);
  }

}



