import { PlatformLocation } from '@angular/common';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor( private router: Router,
    private platformLocation: PlatformLocation,) { }

  canActivate(next: ActivatedRouteSnapshot
    , state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (sessionStorage.getItem('accountSession') != null && JSON.parse(sessionStorage.getItem('accountSession')).isLogged) {
      return true;
    }
    this.router.navigate(['/payment-detail']);
  }


  logoutPortal(merchantId: string, projectId:string){
    if(this.platformLocation.hostname !== 'localhost'){
      return this.router.navigate(['login']);  
    }else{      
      return this.router.navigate(['login/'+merchantId +"/"+projectId]);
    }
  }
}
