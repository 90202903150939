import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { SettingsService } from './app/services/shared/settings.service';
    

platformBrowserDynamic().bootstrapModule(AppModule)
  .then(ref => {
    const settingsService = ref.injector.get(SettingsService);
    if (settingsService.settings.production) {
      enableProdMode();
    }
  } )
  .catch(err => console.error(err));
