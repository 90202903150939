import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SettingsService } from '../shared/settings.service'

let username = 'openpay';
let password = 'openpaypass';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Authorization': 'Basic ' +  btoa(username+':'+password)
  })
}


@Injectable({
  providedIn: 'root'
})
export class PseBanksService {

  constructor(protected http: HttpClient, protected settingsService: SettingsService) { }

  loadBanksCatalog(merchantId: string, projectId: string) {    
    return this.http.get(`${this.settingsService.settings.restUrlPay}${merchantId}/${projectId}/bank`, httpOptions);
  }  


}
