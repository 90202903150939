import {AfterViewChecked, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-payment-3ds',
  templateUrl: './payment-3ds.component.html'
})
export class Payment3dsComponent implements OnInit, AfterViewChecked {

  transactionId: string;

  constructor(
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {  }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
          this.transactionId = params.id;
        }
      );
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }
}



