import Swal from 'sweetalert2';

export class Alert {

    constructor(){}

    public msgGeneral(icon:any, title: string, text:string) {
        Swal.fire({ icon: icon,title: title,text: text });
    }

    public msgSuccess(title: string, text:string) {
        Swal.fire({ icon: 'success',title: title,text: text });
    }

    public alertSuccess(text: string) {
        Swal.fire({ icon: 'success',title: '',text: text });
    }

    public msgWarning(title: string, text:string) {
        Swal.fire({ icon: 'warning',title: title,text: text });
    }

    public alertWarning(text:string) {
        Swal.fire({ icon: 'warning',title: '',text: text });
    }

    public msgInfo(title: string, text:string) {
        Swal.fire({ icon: 'info',title: title,text: text });
    }

    public alertInfo(text:string) {
        Swal.fire({ icon: 'info',title: '',text: text });
    }

    public msgQuestion(title: string, text:string) {
        Swal.fire({ icon: 'question',title: title,text: text });
    }

    public alertQuestion(text:string) {
        Swal.fire({ icon: 'question',title: '',text: text });
    }

    public msgError(title: string, text:string) {
        Swal.fire({ icon: 'error',title: title,text: text });
    }

    public alertError(text:string) {
        Swal.fire({ icon: 'error',title: '',text: text });
    }

}