export class DataValidationModel {
    source: string;
    node: string;
    value: string;
    

    constructor(source: string, node:string, value:string) {
        this.source = source;
        this.node = node;
        this.value = value;
    }
     
}