import { ErrorCode } from './error-code';
import {GeneralMessages} from './general-messages' 

export class ErrorCodeMessages {

    errors={
        "7001":GeneralMessages.MSG_ERROR_BILL_CHANGED_IN_PAYMENT,
        "7002":GeneralMessages.MSG_ERROR_INVALID_STATUS_FOR_PAYMENT
    }

    public getError(code) {
        var msg:string = this.errors[Number(code)]
        if(msg === undefined || msg === null || msg == "") {
            return GeneralMessages.MSG_ERROR_PAYMENT;
        }
        return msg;
    }

}