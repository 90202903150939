import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { DataValidationRequest } from '../../models/data-validation-request.models';
import { SettingsService } from '../shared/settings.service'
let username = 'openpay';
let password = 'openpaypass';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Authorization': 'Basic ' +  btoa(username+':'+password)
  })
}

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(protected http: HttpClient, protected settingsService: SettingsService) { }

  getProjectConfigurations(merchantId: string, projectId: string) {
    return this.http.get(`${this.settingsService.settings.restUrlSvc}project/configurations?merchantId=${merchantId}&projectId=${projectId}&configurationType=login`,httpOptions);    
  }

  getDataValidation(merchantId: string, projectId: string,dataRequest: DataValidationRequest) {
    return this.http.post(`${this.settingsService.settings.restUrlSvc}project/data/validation/${merchantId}/${projectId}`,dataRequest,httpOptions);
  }

  async getProjectConfigurationsByLogin(domainName: string) {    
    return await this.http.get(`${this.settingsService.settings.restUrlSvc}project?domainName=${domainName}`,httpOptions).toPromise();
  }
  
}
