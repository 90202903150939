import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginService } from './services/login/login.service';
import { LoginComponent } from './components/login/login.component';
import { PaymentDetailComponent } from './components/payment-detail/payment-detail.component';
import { ModalPaymentMethodsComponent } from './modals/modal-payment-methods/modal-payment-methods.component';
import { HomeComponent } from './components/home/home/home.component';
import { Paginator } from 'src/app/utils/paginator';
import { MatFormFieldModule,MatButtonModule,MatCheckboxModule,MatRadioModule,MatCardModule,MatToolbarModule,
         MatInputModule,MatPaginatorModule,MatTableModule,MatSortModule,MatPaginatorIntl,
         MatDialogModule,MatTabsModule,MatGridListModule,MatTooltipModule,MatIconModule,MatDividerModule
} from '@angular/material';
import { FooterComponent } from './components/footer/footer.component';
import { CreditCardDirective } from './directives/credit-card.directive';
import { NumbersOnlyInputDirective } from './directives/numbers-only-input.directive';
import { NumbersOnlyDirective } from './directives/numbers-only.directive';
import { LettersOnlyDirective } from './directives/letters-only.directive';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';

import { environment } from '../environments/environment';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { FlexLayoutModule } from '@angular/flex-layout';
import { SettingsHttpService } from './services/shared/settings.http.service';
import {Payment3dsComponent} from "./components/payment-3ds/payment-3ds.component";

export function app_Init(settingsHttpService: SettingsHttpService) {
  return () => settingsHttpService.initializeApp();
}

@NgModule({
  declarations: [AppComponent,LoginComponent,HomeComponent,PaymentDetailComponent, Payment3dsComponent,
    ModalPaymentMethodsComponent,
    FooterComponent,CreditCardDirective,NumbersOnlyInputDirective,NumbersOnlyDirective,LettersOnlyDirective,
  ],
  imports: [BrowserModule,AppRoutingModule,FormsModule,HttpClientModule,
    ReactiveFormsModule,BrowserAnimationsModule,MatFormFieldModule,MatInputModule,
    MatButtonModule,MatCheckboxModule,MatRadioModule,MatCardModule,
    MatToolbarModule,MatPaginatorModule, MatTableModule,MatSortModule,
    MatDialogModule,MatTabsModule,MatGridListModule,MatTooltipModule,MatIconModule,MatDividerModule,
    CurrencyMaskModule, MatButtonModule,
    RecaptchaModule,  //this is the recaptcha main module
    RecaptchaFormsModule, //this is the module for form incase form validation
    FlexLayoutModule
  ],
  exports: [
    MatFormFieldModule,MatInputModule,MatButtonModule,MatCheckboxModule,
    MatRadioModule,MatCardModule,MatToolbarModule,MatPaginatorModule,
    MatTableModule,MatSortModule,MatDialogModule,MatIconModule,MatDividerModule
  ],
  providers: [ LoginService, PaymentDetailComponent,{ provide: MatPaginatorIntl, useClass: Paginator},
    { provide: APP_INITIALIZER, useFactory: app_Init, deps: [SettingsHttpService], multi: true },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptcha.siteKey,
      } as RecaptchaSettings,
    },
  ],
  entryComponents: [ ModalPaymentMethodsComponent ],
  bootstrap: [AppComponent]
})
export class AppModule { }
