import {Component, OnInit, Inject, Input, AfterViewChecked, ChangeDetectorRef, HostListener} from '@angular/core';
import { FormControl,FormGroup, Validators} from '@angular/forms';
import { Alert } from 'src/app/alerts/alert';
import { GeneralMessages } from 'src/app/commons/general-messages';
import { PaymentMethodsService } from 'src/app/services/payment-methods/payment-methods.service';
import { PaymentDetailComponent } from 'src/app/components/payment-detail/payment-detail.component';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { StyleSite } from 'src/app/utils/style-site';
import { PseBanksService } from 'src/app/services/pse-banks/pse-banks-service'
import { ValidateBinService } from 'src/app/services/shared/validate-bin-service'
import { CardRequest } from 'src/app/models/data-card-request.models';
import { DataPayRequest } from 'src/app/models/data-pay-request-models';
import { Banks } from 'src/app/services/pse-banks/Banks';
import { CatalogType } from 'src/app/services/pse-banks/CatalogType';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import { OpenpayService } from 'src/app/services/shared/openpay.service';
import { environment } from '../../../environments/environment';
import { ErrorCodeMessages } from 'src/app/commons/error-code-messages';

declare var $: any;


@Component({
  selector: 'app-modal-payment-methods',
  providers: [Location, {provide: LocationStrategy, useClass: PathLocationStrategy}],
  templateUrl: './modal-payment-methods.component.html',
  styleUrls: ['./modal-payment-methods.component.scss']
})
export class ModalPaymentMethodsComponent implements OnInit, AfterViewChecked {

  public changeSize: boolean  = false;
  frmPaymentMethods: FormGroup;
  alert = new  Alert();
  msg = GeneralMessages;
  list: string[] =[];
  showPaymentMethod = true;
  showPaymentMethodCard = false;
  showPaymentMethodPSE = false;
  showPaymentMethodCash = false;
  showResultPaymentMethod = false;
  activeTab = 0;
  dues = [];
  actualDue = 0;
  personType = null;
  documentType = null;
  step1Done = false;
  step2Done = false;
  step3Done = false;
  encryptMessage = false;
  styleSite = new StyleSite();
  txt_email: FormControl;
  full_name: FormControl;
  card_number: FormControl;
  expire_month: FormControl;
  expire_year: FormControl;
  cvv_number: FormControl;
  dues_number: FormControl;
  mobile_number: FormControl;
  number_document_control: FormControl;
  user_name_pse_control: FormControl;
  phone_pse_control: FormControl;
  email_pse_control: FormControl;
  amout_value_pse_control: FormControl;
  iva_control: FormControl;
  description_pse_control: FormControl;
  currency_pse_control: FormControl;
  address_pse_control: FormControl;

  full_name_store: FormControl;
  mobile_number_store: FormControl;

  source_full_name_store: string;
  source_mobile_number_store: string;

  source_email: string;
  source_full_name: string;
  source_card_number: string;
  source_expire_month: string;
  source_expire_year: string;
  source_cvv_number: string;
  source_dues_number: string;
  source_mobile_number: string;
  paymentMethodSelected: string;
  dataPayRequest = new DataPayRequest();
  bankListArray: Banks[] = [];
  bank: Banks;
  personTypeCatalog: CatalogType[] = [];
  documentTypeCatalog: CatalogType[] = [];
  bankSelect: any;
  bankNameSelect: any;
  personSelected: any;
  duesSelected: any;
  number_document: string;
  user_name_pse: string;
  phone_pse: string;
  email_pse: string;
  amout_value_pse: number;
  amout_value_pse_string: string;
  description_pse: string;
  currency_pse: string;
  address_pse: string;

  r_transaction_id: string;
  r_method: string;
  r_order_id: string;
  r_status: string;
  trx_status: string;
  r_authorization: string;
  r_operation_date: string;
  r_description: string;
  r_amount: string;
  r_iva: string;
  r_list_bill_paid: any[];
  showPrint: boolean;
  flagToLoad: boolean;
  /** Indicaa si el pago fue completado. */
  paymentCompleted:boolean = false;
  cardFlag = false;
  nameInvalid = false;
  cardNumberInvalid = false;
  monthFlag = false;
  expireMonthInvalid = false;
  expireYearInvalid = false;
  yearFlag = false;
  cvvNumberInvalid= false;
  mobileNumberInvalid= false;
  duesInvalid= false;
  bankSpe = false;
  personSpe =false;
  numberDocumentInvalid = false;
  userNameControlInvalid =false;
  phoneControlInvalid = false;
  descriptionControlInvalid = false;
  addressControlInvalid = false;
  documentSpe =false;
  mailFlagMain = false;
  emailInvalid= false;
  nameStoreInvalid= false;
  mobileNumberStoreInvalid= false;
  duesVisible = false;
  paidlist: string = '';
  cardType : string = '';
  errorCodeMsg:ErrorCodeMessages = new ErrorCodeMessages;
  showMethodCard = true;
  showMethodPSE = true;
  showMethodCash = true;
  pseActive = false;

  @Input() paymentDetail: PaymentDetailComponent;
  documentSeleted: any;

  constructor(
    private dialogRef: MatDialogRef<ModalPaymentMethodsComponent>,
    @Inject(MAT_DIALOG_DATA) public dataPay: any,
    private paymentMethodsService: PaymentMethodsService,
    private pseBankService: PseBanksService,
    private validateBinService: ValidateBinService,
    private loc: Location, private openpayService:OpenpayService,
    private cdr: ChangeDetectorRef
    ) {
    this.frmPaymentMethodCard();

  }


  ngOnInit() {
    this.getPayMentmethods();
    this.isPseActive();
    this.settingTheme();
    this.settingPersonType();
    console.log("onModal:"+JSON.stringify(this.dataPay));
    this.getPaidBills(this.dataPay.dataPayRequest.bills_paid);
    if(this.dataPay.transactionId) {
      this.activeTab = 3;
      this.step1Done = true;
      this.step2Done = true;
      this.step3Done = true;
      this.showPaymentMethodCard = false;
      this.showPaymentMethodPSE = false;
      this.showPaymentMethodCash = false;
      this.showResultPaymentMethod =true;
      this.r_transaction_id = this.dataPay.transactionId;
      this.r_authorization = this.dataPay.transactionId;
      this.r_method = this.dataPay.transaction.method == 'PSE' ? this.msg.MSG_PAYMENT_PSE : '';
      this.r_order_id = this.dataPay.transaction.order_id;
      this.r_status = this.dataPay.transaction.status == 'PAID' ? "Su pago fue " + this.msg.MSG_APPROVED : this.dataPay.transaction.status == 'IN_PROGRESS' ?  this.msg.MSG_IN_PROGRESS : "Su pago fue " + this.msg.MSG_NOT_APPROVED;
      this.trx_status = this.dataPay.transaction.status;
      this.r_list_bill_paid = this.dataPay.transaction.bills_paid;
      this.r_list_bill_paid.forEach(element => {
        element.IVA = (element.IVA == null) ? "0" : element.IVA;
      });
      this.r_operation_date = this.dataPay.transaction.operation_date;
      this.r_description = this.dataPay.transaction.description;
      this.r_amount = this.dataPay.transaction.amount;
      this.r_iva = this.dataPay.transaction.iva;

      if (this.dataPay.dataPayRequest){
        this.dataPay.dataPayRequest = {
          amount: this.dataPay.transaction.amount_nf,
          iva: this.dataPay.transaction.iva_nf,
          project_name: this.dataPay.dataPayRequest.project_name
        }
      }
      this.getPaidBills(this.dataPay.transaction.bills_paid);
      console.log("this.dataPay.dataPayRequest.project_name: " + this.dataPay.dataPayRequest.project_name);
      console.log("this.dataPay.dataPayRequest.amount: " + this.dataPay.dataPayRequest.amount);
    }

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    event.target.innerWidth;
    (event.target.innerWidth < 500) ? this.changeSize = true : this.changeSize = false
  }

 frmPaymentMethodCard() {

  this.txt_email = new FormControl('', Validators.compose([
  ])) ;


  this.full_name = new FormControl('',Validators.required),
  this.card_number = new FormControl('',Validators.required),
  this.expire_month = new FormControl('',Validators.required),
  this.expire_year = new FormControl('',Validators.required),
  this.cvv_number = new FormControl('',Validators.required),
  this.dues_number =  new FormControl('',Validators.required),
  this.mobile_number = new FormControl('',Validators.required),
  this.number_document_control = new FormControl('',Validators.required)
  this.user_name_pse_control = new FormControl('',Validators.required)
  this.phone_pse_control = new FormControl('',Validators.required)
  this.email_pse_control = new FormControl('',Validators.required)
  this.amout_value_pse_control = new FormControl('',Validators.required)
  this.iva_control = new FormControl('',Validators.required)
  this.description_pse_control = new FormControl('',Validators.required)
  this.currency_pse_control= new FormControl('',Validators.required)

  this.full_name_store= new FormControl('',Validators.required)
  this.mobile_number_store= new FormControl('',Validators.required)

  this.frmPaymentMethods = new FormGroup ({
    email: new FormGroup({
      txtEmail: this.txt_email
    }) ,
    card: new FormGroup({
      full_name : this.full_name,
      card_number: this.card_number,
      expire_month: this.expire_month,
      expire_year: this.expire_year,
      cvv_number:  this.cvv_number,
      dues_number:  this.dues_number,
      mobile_number:  this.mobile_number
    })  ,

    spe: new FormGroup({
      number_document_control:  this.number_document_control,
      user_name_pse_control : this.user_name_pse_control,
      phone_pse_control : this.phone_pse_control,
      email_pse_control : this.email_pse_control,
      description_pse_control : this.description_pse_control,
    })  ,

    store: new FormGroup({
      full_name_store:  this.full_name_store,
      mobile_number_store : this.mobile_number_store
    })
})


}


  getPaidBills(billsPaid){
    if (billsPaid) {
      billsPaid.forEach(element => {
        this.list.push(element.REFERENCE_PAYMENT);
      });
      this.list.forEach(line => {
        this.paidlist += '• ' + line + '\n';
        return this.paidlist;
        });
    }
  }


/** Payment Service section*/
  validateFullName() {
    return this.msg.MSG_FULL_NAME_REQUIRED;

  }
  validateCardName(event) {
    if(event.target.value.length>0){
    this.nameInvalid = false;
    } else{
      this.nameInvalid = true;
    }
  }

  validateCard() {
    return this.msg.MSG_CARD_REQUIRED;
  }

  validateCardNumber(event) {
    this.cardFlag = false;
    this.cardNumberInvalid = false;
    let number = event.target.value.replace(/\s/g, "");
    if (number.length >= 13) {
      this.cardNumberInvalid = this.validateCardNum(number);
    }
  }


  validateCardNum(cardnumber: any) {
    const regex = new RegExp("^[0-9]{13,19}$");
    if (!regex.test(cardnumber)) {
      return true;
    }
    const cards = [];
    cards[0] = {
      name: "Visa",
      length: "13,16",
      prefixes: "4"
    };
    cards[1] = {
      name: "MasterCard",
      length: "16",
      prefixes: "51,52,53,54,55"
    };
    cards[2] = {
      name: "DinersClub",
      length: "14,19",
      prefixes: "30,36,38"
    };
    cards[3] = {
      name: "CarteBlanche",
      length: "14",
      prefixes: "300,301,302,303,304,305"
    };
    cards[4] = {
      name: "AmEx",
      length: "15",
      prefixes: "34,37"
    };
    cards[5] = {
      name: "Discover",
      length: "16",
      prefixes: "6011,622,64,65"
    };
    cards[6] = {
      name: "JCB",
      length: "16",
      prefixes: "35"
    };
    cards[7] = {
      name: "enRoute",
      length: "15",
      prefixes: "2014,2149"
    };
    cards[8] = {
      name: "Solo",
      length: "16,18,19",
      prefixes: "6334,6767"
    };
    cards[9] = {
      name: "Switch",
      length: "16,18,19",
      prefixes: "4903,4905,4911,4936,564182,633110,6333,6759"
    };
    cards[10] = {
      name: "Maestro",
      length: "12,13,14,15,16,18,19",
      prefixes: "5018,5020,5038,6304,6759,6761,6762,6763"
    };
    cards[11] = {
      name: "VisaElectron",
      length: "16",
      prefixes: "4026,417500,4508,4844,4913,4917"
    };
    cards[12] = {
      name: "LaserCard",
      length: "16,17,18,19",
      prefixes: "6304,6706,6771,6709"
    };

    let lengthValid = false;
    let prefixValid = false;

    for (let i = 0; i < cards.length; i++) {
      const prefix = cards[i].prefixes.split(",");

      for (let j = 0; j < prefix.length; j++) {
        const exp = new RegExp("^" + prefix[j]);
        if (exp.test(cardnumber)) {
          prefixValid = true;
        }
      }
      if (prefixValid) {
        const lengths = cards[i].length.split(",");
        for (let j = 0; j < lengths.length; j++) {
          if (cardnumber.length == lengths[j]) {
            lengthValid = true;
          }
        }
      }
      //Si son true retorna false para no pintar el mensaje
      if (lengthValid && prefixValid) {
        return false;
      }
    }
    return true;
  }

  getCardError(){
    return this.msg.MSG_CARD_VALID;
  }

  validateExpireMonth() {
    return this.msg.MSG_EXPIRE_MONTH_REQUIRED;
  }

  validateMonth(event) {
    this.expireMonthInvalid = false;
    if(event.target.value.length>0){
        if(event.target.value >= 1 && event.target.value <= 12){
          this.monthFlag = false;
          this.getValidMonth();
        }else{
          this.monthFlag = true;
        }

    }else{
      this.expireMonthInvalid = true;
    }
  }
  getMonthError() {
    return this.msg.MSG_EXPIRE_MONTH_VALID;
  }

  validateExpireYear() {
    return this.msg.MSG_EXPIRE_YEAR_REQUIRED;
  }

  validateYear(event) {
    this.expireYearInvalid=false;
    if(event.target.value.length>0){
      let currentYear= (new Date().getFullYear().toString().substr(-2));

      if(event.target.value >= parseInt(currentYear) && event.target.value <35){
        this.yearFlag = false;
        this.getValidMonth();
      }else{
        this.yearFlag = true;
      }

    }else{
      this.expireYearInvalid=true;
    }
  }

  getYearError() {
    return this.msg.MSG_EXPIRE_YEAR_VALID;
  }


  getValidMonth(){
    this.monthFlag= false;
    this.yearFlag = false;
    if(this.source_expire_year != undefined && this.source_expire_year !== ""
      && this.source_expire_month != undefined && this.source_expire_month !==""){
      let year = parseInt(this.source_expire_year);
      let month = parseInt(this.source_expire_month);
      var cardDate = new Date(2000 + year, (month));
      var today = new Date();
      if(cardDate.getFullYear() > today.getFullYear()){
        this.monthFlag= false;
      } else {
        if(cardDate.getFullYear() == today.getFullYear()){
          if (cardDate.getMonth() > today.getMonth()) {
            this.monthFlag= false;
          } else {
            this.monthFlag= true;
          }
        } else {
          this.yearFlag = true;
        }
      }
    }
  }


  validateCVV() {
    return this.msg.MSG_CVV_REQUIRED ;
  }

  validateDues() {
    return this.msg.MSG_DUES_REQUIRED ;
  }
  validateCode(event) {
    this.cvvNumberInvalid = true;
    if(event.target.value.length>1){
      this.cvvNumberInvalid = false;
    }
  }

  validateMovil() {
    return this.msg.MSG_MOVIL_REQUIRED ;
  }

  validatePhone(event) {
    this.mobileNumberInvalid = true;
    if(event.target.value.length>1){
      this.mobileNumberInvalid = false;
    }
  }

  validateBank() {
    return this.msg.MSG_BAK_REQUIRED;
  }

  validatePerson() {
    return this.msg.MSG_PERSON_REQUIRED;
  }

  validateDocument() {
    return this.msg.MSG_DOCUMENT_REQUIRED;
  }

  validateIdNumber() {
    return this.msg.MSG_ID_NUMBER_REQUIRED ;
  }


  validateidNum(event) {
    this.numberDocumentInvalid = true;
    if(event.target.value.length>1){
      this.numberDocumentInvalid = false;
    }
  }

  validateName() {
    return this.msg.MSG_NAME_REQUIRED ;
  }

  validateNameSpe(event) {
    this.userNameControlInvalid = true;
    if(event.target.value.length>1){
      this.userNameControlInvalid = false;
    }
  }

  validateMovilSpe() {
    return this.msg.MSG_MOVIL_PHONE_REQUIRED ;
  }

  validatePhoneSpe(event) {
    this.phoneControlInvalid = true;
    if(event.target.value.length>1){
      this.phoneControlInvalid = false;
    }
  }
  validateMail() {
    return this.msg.MSG_MAIL_REQUIRED;
  }
  validateDescription() {
    return this.msg.MSG_DESCRIPTION_REQUIRED;
  }

  validateAddress() {
    return this.msg.MSG_ADDRESS_REQUIRED;
  }

  validateDescripSpe(event) {
    this.descriptionControlInvalid = true;
    if(event.target.value.length>1){
      this.descriptionControlInvalid = false;
    }
  }

  validateAddressPse(event) {
    if(this.pseActive){
      this.addressControlInvalid = true;
      if(event.target.value.length>1){
        this.addressControlInvalid = false;
      }
    }
  }

  validateMailModal(event) {
    this.emailInvalid=false;
    this.mailFlagMain = false;

    if(event.target.value.length>0){
      const patt = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);;
      let mail = event.target.value;
      this.mailFlagMain = !patt.test(mail);
    }else{
      this.emailInvalid=true;
    }
  }



  getMailStoreError() {
    return this.msg.MSG_VALID_EMAIL_ADDRESS;
  }

  validateFullNameStore() {
   return this.msg.MSG_NAME_REQUIRED;
  }

  validateNameStore(event) {
    this.nameStoreInvalid = true;
    if(event.target.value.length>1){
      this.nameStoreInvalid = false;
    }
  }

  validatePhoneStore() {
    return this.msg.MSG_MOVIL_REQUIRED;
  }
  validateMovilStore(event) {
    this.mobileNumberStoreInvalid = true;
    if(event.target.value.length>1){
      this.mobileNumberStoreInvalid = false;
    }
  }

  arrDuesValue = [
    {'index':'Seleccione cuotas'},{'index':1},{'index':2},{'index':3},{'index':4},{'index':5},
    {'index':6},{'index':7},{'index':9},{'index':10},{'index':11},
    {'index':12},{'index':13},{'index':14},{'index':15},{'index':16},
    {'index':17},{'index':18},{'index':19},{'index':20},
    {'index':21},{'index':22},{'index':23},{'index':24},{'index':25},
    {'index':26},{'index':27},{'index':28},{'index':29},{'index':30},
    {'index':31},{'index':32},{'index':33},{'index':34},{'index':35},{'index':36}
  ];



  paymentForCard() {
    if(this.showMethodCard){
      this.showPaymentMethodPSE = false;
      this.showPaymentMethodCash = false;
      this.showPaymentMethodCard = true;
      this.paymentMethodSelected = 'card';
      $('#card-card-inside').css('border', '1px solid ' + sessionStorage.getItem('portalColor'));
      $('#card-pse-inside').css('border', '1px solid rgba(0,0,0,.125)');
      $('#card-cash-inside').css('border', '1px solid rgba(0,0,0,.125)');
    }
  }

  paymentForPSE() {
    if(this.showMethodPSE){
      this.loadPSEBanks();
      this.showPaymentMethodCard = false;
      this.showPaymentMethodCash = false;
      this.showPaymentMethodPSE = true;
      this.paymentMethodSelected = 'pse';
      $('#card-pse-inside').css('border', '1px solid ' + sessionStorage.getItem('portalColor'));
      $('#card-card-inside').css('border', '1px solid rgba(0,0,0,.125)');
      $('#card-cash-inside').css('border', '1px solid rgba(0,0,0,.125)');
    }
  }

  paymentForCash() {
    if(this.showMethodCash){
      this.showPaymentMethodCard = false;
      this.showPaymentMethodPSE = false;
      this.showPaymentMethodCash = true;
      this.paymentMethodSelected = 'cash';
      $('#card-cash-inside').css('border', '1px solid ' + sessionStorage.getItem('portalColor'));
      $('#card-card-inside').css('border', '1px solid rgba(0,0,0,.125)');
      $('#card-pse-inside').css('border', '1px solid rgba(0,0,0,.125)');
    }
  }

  mouseoverCard(){
    if(this.showMethodCard){
      $('#card-card-inside').css('border', '1px solid ' + sessionStorage.getItem('portalColor'));
    }
  }

  mouseoutCard(){
    if(this.showMethodCard && this.showPaymentMethodCard === false){
      $('#card-card-inside').css('border', '1px solid rgba(0,0,0,.125)');
    }
  }


  mouseoverPse(){
    if(this.showMethodPSE){
      $('#card-pse-inside').css('border', '1px solid ' + sessionStorage.getItem('portalColor'));
    }
  }

  mouseoutPse(){
    if(this.showMethodPSE && this.showPaymentMethodPSE === false){
      $('#card-pse-inside').css('border', '1px solid rgba(0,0,0,.125)');
    }
  }


  mouseoverCash(){
    if(this.showMethodCash){
      $('#card-cash-inside').css('border', '1px solid ' + sessionStorage.getItem('portalColor'));
    }
  }

  mouseoutCash(){
    if(this.showMethodCash && this.showPaymentMethodCash === false){
        $('#card-cash-inside').css('border', '1px solid rgba(0,0,0,.125)');

    }
  }

  nextStep() {
    this.emailInvalid=false;
    this.mailFlagMain = false;
    let mail = this.txt_email.value;
    if(mail === undefined||mail === ""){
      this.mailFlagMain = false;
      this.emailInvalid =true;
    } else {
      this.emailInvalid =false;
      const patt = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
      if(!patt.test(mail)){
        this.mailFlagMain = true;
      } else {
      this.step1Done = true;
      if (this.activeTab != 3) {
        if (this.activeTab == 0) {
          this.activeTab = this.activeTab + 1;
        } else if (this.activeTab == 1) {
          if (this.showPaymentMethodCard == true || this.showPaymentMethodPSE == true || this.showPaymentMethodCash == true) {
            this.step2Done = true;
            this.encryptMessage = true;
            this.activeTab = this.activeTab + 1;
            if (this.showPaymentMethodPSE == true ) {
              console.log(this.dataPay.dataPayRequest.amount);
              this.amout_value_pse = this.dataPay.dataPayRequest.amount;
              this.amout_value_pse_string = "$ " +this.roundTotalValue(this.dataPay.dataPayRequest.amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              this.email_pse = this.source_email;
              this.currency_pse = "COP";
            }
          } else {
            this.alert.alertWarning(this.msg.MSG_SELECT_PAYMENT_METHOD);
          }
        }


      }
    }
  }
}

  backStep() {
    this.encryptMessage = false;
    if (this.activeTab != 3) {
      this.activeTab = this.activeTab - 1;
    }
  }

  getDueValue(){
    $(".dues li a").click(function(){
      var selText = $(this).text();
      $(this).parents('.dropdown').find('.dropdown-toggle').html(selText + ' <span class="caret"></span>');
      this.actualDue = selText;
    });
  }


  getPersonTypeValue() {
    $(".personType li a").click(function () {
      var selText = $(this).text();
      $(this).parents('.dropdown').find('.dropdown-toggle').html(selText + ' <span class="caret"></span>');
      if (selText === 'Juridica') {
        this.personType = 0;
      } else if (selText === 'Moral') {
        this.personType = 1;
      }
    });
  }


  getDocumentTypeValue() {
    $(".documentType li a").click(function () {
      var selText = $(this).text();
      $(this).parents('.dropdown').find('.dropdown-toggle').html(selText + ' <span class="caret"></span>');
      if (selText === 'Cédula de ciudadanía') {
        this.documentType = '03';
      } else if (selText === 'Cédula de extranjería') {
        this.documentType = '05';
      } else if (selText === 'Número movil') {
        this.documentType = 'NM';
      } else if (selText === 'Documento identificación extranjería') {
        this.documentType = '08';
      } else if (selText === 'Identificación único cliente') {
        this.documentType = 'IU';
      } else if (selText === 'Nit') {
        this.documentType = '06';
      } else if (selText === 'Pasaporte') {
        this.documentType = '07';
      } else if (selText === 'Registro civil nacimiento') {
        this.documentType = '01';
      } else if (selText === 'Tarjeta identidad') {
        this.documentType = '02';
      }
    });
  }


  /**
   * Do Payment
   *
  */
  doPayment() {

    if(this.showPaymentMethodCard) {
      this.doPaymentCard();
    }

    if(this.showPaymentMethodPSE) {
      this.doPaymentPSE();
    }

    if(this.showPaymentMethodCash) {
      this.doPaymentStore();
    }

  }
  doPaymentStore() {
  let isValid = true;
  if(this.full_name_store.value === undefined || this.full_name_store.value ===""){
    isValid = false;
    this.nameStoreInvalid =true;
    }

  if(this.mobile_number_store.value === undefined || this.mobile_number_store.value ===""){
    isValid = false;
    this.mobileNumberStoreInvalid =true;
    }
  if(isValid){
    this.flagToLoad=true;
    this.step3Done = true;
    this.dialogRef.disableClose = true;
    this.dataPayRequest.email = this.source_email;
    this.dataPayRequest.charge_type = "STORE";
    this.dataPayRequest.product_type = "PAYMENT_LINK";
    this.dataPayRequest.customer_name = this.source_full_name_store;
    this.dataPayRequest.phone_number = this.source_mobile_number_store;
    this.dataPayRequest.iva = this.dataPay.dataPayRequest.iva;
    this.dataPayRequest.amount = this.dataPay.dataPayRequest.amount;
    this.dataPayRequest.data = this.dataPay.dataPayRequest.data;
    this.dataPayRequest.records_key = [];
    this.dataPay.dataPayRequest.bills_paid.forEach(element => {
      element.IVA = (element.IVA == null) ? "0" : element.IVA;
      this.dataPayRequest.records_key.push(element.RECORD_KEY)
      });
    this.dataPayRequest.bills_paid = this.dataPay.dataPayRequest.bills_paid;
    this.dataPayRequest.description = 'Pago recaudo de ' + this.dataPay.dataPayRequest.project_name;
    this.dataPayRequest.currency = 'COP';

    this.paymentMethodsService.savePaymentForCard(this.dataPay.merchantId,this.dataPay.projectId,this.dataPayRequest).
    subscribe((data: any) => {
      if(data.url_redirect){
          this.alert.alertSuccess('Referencia de pago, creada exitosamente. ');
          $('#frmPaymentMethods').hide();
          $('#receiptStores').show();
          this.showPrint = true;
          this.flagToLoad=false;
          $.get(data.url_redirect, function(data) {
            data= data.replace('<link rel="stylesheet" href="/css/paynetStoreReceipt.css" type="text/css" media="all"/>' , '<link href="https://api.openpay.co/css/paynetStoreReceipt.css" rel="stylesheet" media="all"/>');
            data= data.replace('class="whitepaper"', 'class=""');
            data= data.replace('<div class="pay-amount">', '<div class="pay-amount" style="display: flex; align-items: center; justify-content: center;">');
            data= data.replace('<div class="label-head-steaps">', '<div class="label-head-steaps" style="display: flex; align-items: center;">');
            data= data.replace('<div class="steaps-foot">', '<div>');
            $("#receiptStores").html(data);
          });
          this.paymentCompleted = true;
        } else{
          this.alert.alertError(this.msg.MSG_ERROR_PAYMENT);
          this.showPrint = false;
          this.flagToLoad=false;
        }
      },(error) => {
        console.log("ERROR =>"+JSON.stringify(error));
        if (error.error !== undefined && error.error.description !== undefined) {
          this.alert.alertError(error.error.description);
        }else{
          if(error.error != undefined && error.error.error_code != undefined) {
            this.alert.alertWarning(this.errorCodeMsg.getError(error.error.error_code));
            this.paymentCompleted = true;
            this.closeDialog();
          } else {
            this.alert.alertError(this.msg.MSG_ERROR_PAYMENT);
          }
        }
        this.flagToLoad=false;
        this.dialogRef.disableClose = false;
      });
    }
  }

  closeDialog(){
    this.dialogRef.close({'paymentCompleted':this.paymentCompleted});
  }

  printReceipt(){
     var wnd = window.open("about:blank", "", "_blank");
      wnd.document.write('<html><head><title>' + document.title  + '</title>');
      wnd.document.write('</head><body >');
      wnd.document.write(document.getElementById('receiptStores').innerHTML);
      wnd.document.write('</body></html>');
      wnd.document.close();
      console.log(document.getElementById('receiptStores'));
      console.log(wnd);
      wnd.print();
     return true;
  }


  /**
   * Payment for PSE
   *
  */
 doPaymentPSE(){
  let isValid = true;

  if(this.bankSelect === undefined || this.bankSelect === ""){
    isValid = false;
    this.bankSpe =true;
   }
   if(this.personSelected === undefined || this.personSelected === "-"){
    isValid = false;
    this.personSpe =true;
   }
   if(this.documentSeleted === undefined || this.documentSeleted === "-"){
    isValid = false;
    this.documentSpe =true;
   }

   if(this.number_document_control.value === undefined || this.number_document_control.value ===""){
    isValid = false;
    this.numberDocumentInvalid =true;
   }
   if(!this.pseActive){
    if(this.user_name_pse_control.value === undefined || this.user_name_pse_control.value ===""){
      isValid = false;
      this.userNameControlInvalid =true;
    }
  }else{
    this.user_name_pse="ser_name_pse";
    this.phone_pse="1234567890";
    this.address_pse="address_pse";
  }
  if(!this.pseActive){
    if(this.phone_pse_control.value === undefined || this.phone_pse_control.value ===""){
      isValid = false;
      this.phoneControlInvalid =true;
    }
  }
   if(this.description_pse_control.value === undefined || this.description_pse_control.value ===""){
    isValid = false;
    this.descriptionControlInvalid =true;
   }

   if(isValid){
    this.flagToLoad=true;
    this.step3Done = true;
    this.dialogRef.disableClose = true;

    let deviceSessionId = this.getDeviceSessionId();
    this.dataPayRequest.email = this.email_pse;
    this.dataPayRequest.charge_type = "PSE";
    this.dataPayRequest.product_type = "PAYMENT_LINK";
    this.dataPayRequest.customer_name = this.user_name_pse;
    this.dataPayRequest.phone_number = this.phone_pse;
    this.dataPayRequest.device_session_id = deviceSessionId;
    this.dataPayRequest.amount = this.dataPay.dataPayRequest.amount;
    this.dataPayRequest.data = this.dataPay.dataPayRequest.data;
    this.dataPayRequest.records_key = [];
    this.dataPay.dataPayRequest.bills_paid.forEach(element => {
      element.IVA = (element.IVA == null) ? "0" : element.IVA;
      this.dataPayRequest.records_key.push(element.RECORD_KEY)
    });
    this.dataPayRequest.bills_paid = this.dataPay.dataPayRequest.bills_paid;
    this.dataPayRequest.financial_institution_code = this.bankSelect;
    this.dataPayRequest.financial_institution_name = this.bankNameSelect;
    this.dataPayRequest.address = this.address_pse;
    this.dataPayRequest.user_type = this.personSelected;
    this.dataPayRequest.customer_document_type = this.documentSeleted;
    this.dataPayRequest.customer_document_number = this.number_document;
    this.dataPayRequest.iva = this.dataPay.dataPayRequest.iva;
    this.dataPayRequest.description = this.description_pse;
    this.dataPayRequest.currency = this.currency_pse;
    console.log(this.dataPayRequest.amount);
    this.dataPayRequest.redirect_url = this.getRedirectURL(this.dataPay.merchantId, this.dataPay.projectId);
    this.paymentMethodsService.savePaymentForCard(this.dataPay.merchantId,this.dataPay.projectId,this.dataPayRequest).
      subscribe((data: any) => {
        console.log("OK ==> "+JSON.stringify(data));
        this.paymentCompleted = true;
        window.location.href = data.url_redirect;
      },(error) => {
        console.log("ERROR =>"+JSON.stringify(error));
        if (error.error !== undefined && error.error.description !== undefined) {
          this.alert.alertError(error.error.description);
        } else {
          if(error.error != undefined && error.error.error_code != undefined) {
            this.alert.alertWarning(this.errorCodeMsg.getError(error.error.error_code));
            this.paymentCompleted = true;
            this.closeDialog();
          } else {
            this.alert.alertError(this.msg.MSG_ERROR_PAYMENT);
          }
        }
        this.flagToLoad=false;
        this.dialogRef.disableClose = false;
      });

  }
 }

  private getRedirectURL(merchantId:string, projectId:string) {
    let angularRoute = this.loc.path();
    let url = window.location.href;

    let domainAndApp = url.replace(angularRoute, '');
    return domainAndApp + "/payment-detail";
  }

  private getDeviceSessionId():any {
    return this.openpayService.getDeviceSessionId(
      "frmPaymentMethods", "deviceIdHiddenFieldName", this.dataPay.merchantId, this.dataPay.publicId);
  }


  /**
   * Payment for Card
   *
  */
  doPaymentCard() {
   let isValid = true;
   const CREDIT ="CREDIT";

    if(this.full_name.value === undefined || this.full_name.value === ""){
      this.nameInvalid=true;
      isValid = false;
    }
    if(this.card_number.value === undefined || this.card_number.value === ""){
      this.cardNumberInvalid=true;
      isValid = false;
    }
    if(this.expire_month.value === undefined || this.expire_month.value === ""){
      this.expireMonthInvalid=true;
      isValid = false;
    }
    if(this.expire_year.value === undefined || this.expire_year.value === ""){
      this.expireYearInvalid=true;
      isValid = false;
    }
    if(this.cvv_number.value === undefined || this.cvv_number.value === ""){
      this.cvvNumberInvalid=true;
      isValid = false;
    }
    if(this.mobile_number.value === undefined || this.mobile_number.value === ""){
      this.mobileNumberInvalid=true;
      isValid = false;
    }
    if(CREDIT === this.cardType){
      if(this.duesSelected === undefined || this.duesSelected.value === ""){
        this.duesInvalid=true;
        isValid = false;
      }
    }

    if(isValid){
      this.flagToLoad=true;
      this.step3Done = true;
      this.dialogRef.disableClose = true;

      let deviceSessionId = this.getDeviceSessionId();
      this.dataPayRequest.email = this.source_email;
      this.dataPayRequest.charge_type = "CARD";
      this.dataPayRequest.product_type = "PAYMENT_LINK";
      this.dataPayRequest.customer_name = this.source_full_name;
      this.dataPayRequest.phone_number = this.source_mobile_number;
      this.dataPayRequest.device_session_id = deviceSessionId;
      this.dataPayRequest.amount = this.dataPay.dataPayRequest.amount;
      this.dataPayRequest.data = this.dataPay.dataPayRequest.data;
      this.dataPayRequest.records_key = [];
      this.dataPay.dataPayRequest.bills_paid.forEach(element => {
        element.IVA = (element.IVA == null) ? "0" : element.IVA;
        this.dataPayRequest.records_key.push(element.RECORD_KEY)
      });
      this.dataPayRequest.iva = this.dataPay.dataPayRequest.iva;
      this.dataPayRequest.bills_paid = this.dataPay.dataPayRequest.bills_paid;
      this.dataPayRequest.source = new CardRequest();
      this.dataPayRequest.source.holder_name = this.source_full_name;

      this.dataPayRequest.source.card_number = this.source_card_number.replace(/\s/g, "");
      this.dataPayRequest.source.cvv2 = this.source_cvv_number;
      this.dataPayRequest.source.expiration_month = this.source_expire_month;
      this.dataPayRequest.source.expiration_year =  this.source_expire_year;
      if(CREDIT === this.cardType){
        this.dataPayRequest.dues_number =this.duesSelected;
      }else{
        this.dataPayRequest.dues_number ="1";
      }
      const loc = document.location;
      this.dataPayRequest.redirect_url = loc.origin + "/payment3ds/";
      console.log("objet: "+JSON.stringify(this.dataPayRequest));
      this.paymentMethodsService.savePaymentForCard(this.dataPay.merchantId,this.dataPay.projectId,this.dataPayRequest).
      subscribe((data: any) => {
        console.log("OK: "+JSON.stringify(data));
        if(data.payment_method != null && data.payment_method.url != null){
          window.location.href = data.payment_method.url
        }else{
          this.showPaymentMethodCard = false;
          this.showResultPaymentMethod =true;
          this.r_transaction_id = data.transaction_id;
          this.r_method = data.method == 'card' ? this.msg.MSG_PAYMENT_CARD : '';
          this.r_authorization = data.authorization;
          this.r_operation_date = data.operation_date;
          this.r_description = data.description;
          this.r_amount = data.amount;
          this.r_iva = data.iva;
          this.r_order_id = data.order_id;
          this.r_status = data.status == 'completed' ?  this.msg.MSG_APPROVED : this.msg.MSG_NOT_APPROVED;
          this.trx_status = data.status;
          this.r_list_bill_paid = this.dataPay.dataPayRequest.bills_paid;
          this.paymentCompleted = true;
          this.flagToLoad=false;
        }

      },(error) => {
          console.log("ERROR =>"+JSON.stringify(error));
          if (error.error !== undefined && error.error.description !== undefined) {
            this.alert.alertError(error.error.description);
          } else {
            if(error.error != undefined && error.error.error_code != undefined) {
              this.alert.alertWarning(this.errorCodeMsg.getError(error.error.error_code));
              this.paymentCompleted = true;
              this.closeDialog();
            } else {
              this.alert.alertError(this.msg.MSG_ERROR_PAYMENT);
            }
          }
          this.flagToLoad=false;
          this.dialogRef.disableClose = false;
        });
    }
  }


  settingTheme() {
    var portalColor = sessionStorage.getItem('portalColor');
    this.styleSite.setColor(portalColor);
    $('mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar').css("background-color", portalColor);
  }

  loadPSEBanks(){
    this.pseBankService.loadBanksCatalog(this.dataPay.merchantId, this.dataPay.dataPayRequest.projectPublicId)
      .subscribe(
        (data: any) => {
          for (var key in data) {
              this.bank = new Banks(key, data[key] + '');
              this.bankListArray.push(this.bank);
          }
        },(error) => {
          if (error.error !== undefined && error.error.description !== undefined) {
            this.alert.alertError(error.error.description);
          }else{
            this.alert.alertWarning(this.msg.MSG_SERV_NOT_AVAILABLE);
          }
        });
  }

  loadBiases(event){
    if(event.target.value.length>1){
      let bin = event.target.value.substr(0,7).replace(/ /g, "");
      const CREDIT ="CREDIT";
      this.validateBinService.getTypeCard(bin)
        .subscribe(
          (data: any) => {
            this.cardType =data.card_type;
            if (CREDIT === this.cardType){
              this.arrDuesValue = [
                {'index':'Seleccione cuotas'},{'index':1},{'index':2},{'index':3},{'index':4},{'index':5},
                {'index':6},{'index':7},{'index':9},{'index':10},{'index':11},
                {'index':12},{'index':13},{'index':14},{'index':15},{'index':16},
                {'index':17},{'index':18},{'index':19},{'index':20},
                {'index':21},{'index':22},{'index':23},{'index':24},{'index':25},
                {'index':26},{'index':27},{'index':28},{'index':29},{'index':30},
                {'index':31},{'index':32},{'index':33},{'index':34},{'index':35},{'index':36}
              ];
              this.duesVisible =true;
              this.duesInvalid = false;

            }else{
              this.arrDuesValue = [];
              this.duesVisible =false;
            }
          },(error) => {
            this.alert.alertWarning(this.msg.MSG_SERV_NOT_AVAILABLE);
          });


      }
  }

  trackByFn() {
    return this.arrDuesValue;
  }

  getBankValue(){
    $(".banks li a").click(function(){
      var selText = $(this).text();
      $(this).parents('.dropdown').find('.dropdown-toggle').html(selText + ' <span class="caret"></span>');
      this.actualDue = selText;
    });
  }
  setBankValue(bank){
    this.bankSpe = false;
    this.bankSelect = bank.code;
    this.bankNameSelect =bank.name;
    console.log(bank);
    console.log(this.bankNameSelect);
  }

  settingPersonType(){
    var sel = new CatalogType();
    sel.code = '-';
    sel.value = 'A continuación seleccione tipo de persona';
    this.personTypeCatalog.push(sel);

    var person1 = new CatalogType();
    person1.code = '0';
    person1.value = 'Natural';
    this.personTypeCatalog.push(person1);

    var person2 = new CatalogType();
    person2.code = '1';
    person2.value = 'Juridica';
    this.personTypeCatalog.push(person2);
  }

  getPersonType(){
    $(".personType li a").click(function(){
      var selText = $(this).text();
      $(this).parents('.dropdown').find('.dropdown-toggle').html(selText + ' <span class="caret"></span>');
      this.actualDue = selText;
    });
  }

  setPersonType(person){
    this.personSpe = false;
    this.personSelected = person.code;
    if( this.personSelected === "-"){
      this.personSpe =true;
     }
  }

  setDues(due){
    this.duesInvalid = false;
    this.duesSelected = due.index;
    if( this.duesSelected === ""){
      this.duesInvalid =true;
     }
  }

  settingDocumentType(){
    var sel = new CatalogType();
    sel.code = '-';
    sel.value = 'A continuación seleccione tipo de documento';
    this.documentTypeCatalog.push(sel);

    var document2 = new CatalogType();
    document2.code = '03';
    document2.value = 'Cédula de ciudadanía';
    this.documentTypeCatalog.push(document2);

    var document3 = new CatalogType();
    document3.code = '05';
    document3.value = 'Cédula de extranjería';
    this.documentTypeCatalog.push(document3);

    var document4 = new CatalogType();
    document4.code = 'NM';
    document4.value = 'Número movil';
    this.documentTypeCatalog.push(document4);

    var document5 = new CatalogType();
    document5.code = '08';
    document5.value = 'Documento identificación extranjería';
    this.documentTypeCatalog.push(document5);

    var document6 = new CatalogType();
    document6.code = 'IU';
    document6.value = 'Identificación único cliente';
    this.documentTypeCatalog.push(document6);

    var document8 = new CatalogType();
    document8.code = '06';
    document8.value = 'Nit';
    this.documentTypeCatalog.push(document8);

    var document9 = new CatalogType();
    document9.code = '07';
    document9.value = 'Pasaporte';
    this.documentTypeCatalog.push(document9);

    var document10 = new CatalogType();
    document10.code = '01';
    document10.value = 'Registro civil nacimiento';
    this.documentTypeCatalog.push(document10);

    var document11 = new CatalogType();
    document11.code = '02';
    document11.value = 'Tarjeta identidad';
    this.documentTypeCatalog.push(document11);
  }

  settingDocumentTypePse(){
    var sel = new CatalogType();
    sel.code = '-';
    sel.value = 'A continuación seleccione tipo de documento';
    this.documentTypeCatalog.push(sel);

    var document1 = new CatalogType();
    document1.code = '01';
    document1.value = 'Registro civil de nacimiento';
    this.documentTypeCatalog.push(document1);

    var document2 = new CatalogType();
    document2.code = '02';
    document2.value = 'Tarjeta de identidad';
    this.documentTypeCatalog.push(document2);

    var document3 = new CatalogType();
    document3.code = '03';
    document3.value = 'Cédula de ciudadanía';
    this.documentTypeCatalog.push(document3);

    var document4 = new CatalogType();
    document4.code = '04';
    document4.value = 'Tarjeta de extranjería';
    this.documentTypeCatalog.push(document4);

    var document5 = new CatalogType();
    document5.code = '05';
    document5.value = 'Cédula de extranjería';
    this.documentTypeCatalog.push(document5);

    var document6 = new CatalogType();
    document6.code = '06';
    document6.value = 'Nit';
    this.documentTypeCatalog.push(document6);

    var document7 = new CatalogType();
    document7.code = '07';
    document7.value = 'Pasaporte';
    this.documentTypeCatalog.push(document7);

    var document8 = new CatalogType();
    document8.code = '08';
    document8.value = 'Documento de identificacion extranjero';
    this.documentTypeCatalog.push(document8);
  }

  getDocumentType(){
    $(".documentType li a").click(function(){
      var selText = $(this).text();
      $(this).parents('.dropdown').find('.dropdown-toggle').html(selText + ' <span class="caret"></span>');
      this.actualDue = selText;
    });
  }

  setDocumentType(document){
    this.documentSpe =false;
    this.documentSeleted = document.code;
    if( this.documentSeleted === "-"){
      this.documentSpe =true;
     }

  }
  getPayMentmethods(){
    this.paymentMethodsService.getPayMentmethods(this.dataPay.merchantId,this.dataPay.projectId).
    subscribe((data: any) => {
      console.log("getPayMentmethods: "+JSON.stringify(data));
      this.showMethodCard = data.allowCard;
      this.showMethodPSE = data.allowPse;
      this.showMethodCash = data.allowStore;

      if(this.showMethodCard){
        $('#card-card-inside').css("background-color", "transparent");
      }else{
        $('#card-card-inside').css("background-color", "#80808085");
      }
      if(this.showMethodPSE){
        $('#card-pse-inside').css("background-color", "transparent");
      }else{
        $('#card-pse-inside').css("background-color", "#80808085");
      }
      if(this.showMethodCash){
        $('#card-cash-inside').css("background-color", "transparent");
      }else{
        $('#card-cash-inside').css("background-color", "#80808085");
      }
    },(error) => {
        console.log("ERROR =>"+JSON.stringify(error));
        if (error.error !== undefined && error.error.description !== undefined) {
          this.alert.alertError(error.error.description);
        }
      });
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  isPseActive(){
    this.paymentMethodsService.isPseActive(this.dataPay.merchantId,this.dataPay.projectId).
    subscribe((data: any) => {
      console.log("isPseActive: "+JSON.stringify(data));
      this.pseActive = JSON.parse(data.pseActive);
      if(this.pseActive){
        this.settingDocumentTypePse()
      }else{
        this.settingDocumentType();
      }
    },(error) => {
        console.log("ERROR PSE FLAG =>"+JSON.stringify(error));
        this.settingDocumentType();
      });
  }

  roundTotalValue(value: number) : number {
    return Math.ceil(value);
  }

}
