import { Injectable, OnInit } from '@angular/core';
import { SettingsService } from '../shared/settings.service'
declare var OpenPay: any;

@Injectable({
  providedIn: 'root'
})
export class OpenpayService {

  constructor(protected settingsService: SettingsService) { }

  public getDeviceSessionId(formId:String, deviceIdHiddenFieldName:String, merchantId:String, publicId:String):any {
    OpenPay.setSandboxMode(true); 
    OpenPay.setId(merchantId);
    OpenPay.setApiKey(publicId);
    OpenPay.sandboxHostname = this.settingsService.settings.apiUrl;
    OpenPay.deviceData._sandboxHostname = this.settingsService.settings.apiUrl;

    return OpenPay.deviceData.setup("frmPaymentMethods", "deviceIdHiddenFieldName");

  }



}
