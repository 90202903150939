import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SettingsService } from '../shared/settings.service'

import { DataValidationRequest } from '../../models/data-validation-request.models';
import { DataPayRequest } from 'src/app/models/data-pay-request-models';

let username = 'openpay';
let password = 'openpaypass';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Authorization': 'Basic ' +  btoa(username+':'+password)
  })
}


@Injectable({
  providedIn: 'root'
})
export class PaymentMethodsService {

  constructor(protected http: HttpClient, protected settingsService: SettingsService) { }

  savePaymentForCard(merchantId: string, projectId: string,dataRequest: DataPayRequest) { 
    return this.http.post(`${this.settingsService.settings.restUrlPay}${merchantId}/${projectId}/charge`,dataRequest,httpOptions)    
  }

  getReceiptStore(merchantId: string, projectId: string,chargeId: string) { 
    return this.http.get(`${this.settingsService.settings.restUrlPay}${merchantId}/${projectId}/receipt/${chargeId}`,httpOptions);    
  }

  savePaymentForPSE(merchantId: string, projectId: string,dataRequest: DataValidationRequest) {
    return this.http.post(`${this.settingsService.settings.restUrlSvc}project/data/validation/${merchantId}/${projectId}`,dataRequest,httpOptions);    
  }

  savePaymentForCash(merchantId: string, projectId: string,dataRequest: DataValidationRequest) {
    return this.http.post(`${this.settingsService.settings.restUrlSvc}project/data/validation/${merchantId}/${projectId}`,dataRequest,httpOptions);    
  }

  getPaidStatus(merchantId: string, projectId: string,dataRequest: DataValidationRequest) {
    return this.http.post(`${this.settingsService.settings.restUrlSvc}project/data/validation/${merchantId}/${projectId}`,dataRequest,httpOptions);    
  }

  getPayMentmethods(merchantId: string, projectId: string) { 
    return this.http.get(`${this.settingsService.settings.restUrlPay}${merchantId}/${projectId}/payment_methods`,httpOptions);    
  }

  isPseActive(merchantId: string, projectId: string) {   
    return this.http.get(`${this.settingsService.settings.restUrlPay}${merchantId}/${projectId}/chek_pse`,httpOptions); 
  }

}
