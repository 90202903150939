import { CardRequest } from './data-card-request.models';

export class  DataPayRequest {
    email: string;
    charge_type: string;
    customer_name: string;
    source_id: string;
    phone_number: string;
    amount: number;
    device_session_id: string;
    source: CardRequest;
    data: any[];
    bills_paid: any[];
    project_name: string
    projectPublicId: string;
    financial_institution_code: string;
    financial_institution_name: string;
    user_type: string;
    customer_document_number: string;
    customer_document_type: string;
    iva: number;
    description: string;
    currency: string;
    redirect_url:string;
    records_key: string[]= [];
    dues_number: string;
    address: string;
    product_type: string;
    constructor() {}
}

