import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './components/login/login.component';
import { PaymentDetailComponent } from './components/payment-detail/payment-detail.component';
import { HomeComponent } from './components/home/home/home.component';
import { Payment3dsComponent } from './components/payment-3ds/payment-3ds.component';


const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'login/:merchantId/:projectId', component: LoginComponent },
  { path: 'payment-detail', component: PaymentDetailComponent  },
  { path: 'payment3ds', component: Payment3dsComponent  },
  { path: '', pathMatch: 'full', component: HomeComponent },
  { path: '**', pathMatch: 'full', component: HomeComponent }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
