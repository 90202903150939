/**
 * Settings a usar en lugar de Environment
 * Se cargan de forma dinamica de acuerdo a variables de ambiente para la imagen docker
 * en ambiente local se cargan con settings.json
 */ 
export class Settings {
    apiUrl: string;   
    production: boolean;
    //recaudo-services
    restUrlSvc: string;
    //recaudo-portal
    restUrlPay: string;
}