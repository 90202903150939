import { Directive, HostListener, ElementRef } from '@angular/core';


@Directive({
    selector: '[only-number]'
  })
export class NumbersOnlyInputDirective {

  constructor(private elRef: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event) {
    var patt = new RegExp(/^[0-9\s]+$/g);
    var bandera:boolean;
    let initalValue = this.elRef.nativeElement.value;
    bandera = patt.test(initalValue);
    bandera ? "" : this.elRef.nativeElement.value="";
  }

}