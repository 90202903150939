import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, NgForm } from '@angular/forms';
import { LoginService } from 'src/app/services/login/login.service';
import { DataValidationRequest } from 'src/app/models/data-validation-request.models';
import { DataValidationModel } from 'src/app/models/data-validation.models';
import { GeneralMessages } from 'src/app/commons/general-messages';
import { Alert } from 'src/app/alerts/alert';
import { StyleSite } from 'src/app/utils/style-site';

import { PlatformLocation } from '@angular/common';


declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit { 

  title = 'recaudo-portal';
  logoPath: string; 
  portalColor: string;
  items: any[] = [];
  reqValidation: string;
  frmLogin: FormGroup;
  alert = new  Alert();
  styleSite = new StyleSite();
  merchantId: string;
  projectId: string;
  msg = GeneralMessages;
  dataLoginShared: DataValidationRequest;  
  tokenCaptcha: string|undefined;

  constructor(protected loginService: LoginService,
              protected formBuilder: FormBuilder,
              protected route: ActivatedRoute,
              protected router: Router,
              private platformLocation: PlatformLocation,
              ) { this.tokenCaptcha = undefined; }




  ngOnInit() {    
      if(this.platformLocation.hostname !== 'localhost'){
        let response = this.loginService.getProjectConfigurationsByLogin(this.platformLocation.hostname + "/login");
        response.then((data) => {          
          var dataBody: any[] = JSON.parse(JSON.stringify(data));          
          if(dataBody.length > 0){
            this.merchantId = dataBody[0].merchantId;
            this.projectId = dataBody[0].publicId;
            this.getProjectConfigurations(this.merchantId, this.projectId);
          }else{
            this.alert.alertError(this.msg.MSG_SERV_NOT_AVAILABLE);
          }         
        });
        this.frmLogin = new FormGroup({});                   
      }else{      
        this.merchantId = this.route.snapshot.params.merchantId;
        this.projectId = this.route.snapshot.params.projectId;
        this.getProjectConfigurations(this.merchantId, this.projectId);
      }    
  }

  getProjectConfigurations(merchantId: string, projectId:string) {
    let group = {};
    group['recaptchaReactive']=new FormControl('');
    this.loginService.getProjectConfigurations(merchantId,projectId).
    subscribe(
      (data: any) => {
        sessionStorage.setItem("project",JSON.stringify({"merchantId":merchantId, "projectId":projectId}));
        this.styleSite.logoPath = data.logoPath;
        this.logoPath = data.logoPath;
        this.styleSite.setColor(data.portalColor);        
        sessionStorage.setItem('portalColor',data.portalColor);
        this.items = data['configurationType'].login;
        if(null != this.items) {
          for(let input_template of this.items  ) {
            group[input_template.name]=new FormControl('');
          }
        } else {
          this.alert.alertWarning(this.msg.MSG_SERV_NOT_AVAILABLE);
        }
      },(error) => {
        console.log("ERROR =>"+JSON.stringify(error));
        if("400" == error.status) {
          this.alert.alertInfo(this.msg.MSG_NO_RESULTS_FOUND);
        } else if(0 == error.status) {
          this.alert.alertError(this.msg.MSG_CONNECT_ERROR);
        }else{
          this.alert.alertError(this.msg.MSG_SERV_NOT_AVAILABLE);
        }
      }
    );
    this.frmLogin = new FormGroup(group);
  }

validateLogin(){    
    let vSource = 'detail';
    let request = new DataValidationRequest();
    request.token = this.tokenCaptcha;    
    for(let i=0; i<this.items.length; i++){
      request.data.push(new DataValidationModel(vSource,this.items[i].name,this.frmLogin.controls[this.items[i].name].value));
    }
    this.dataLoginShared = request;
    //if( this.validateFieldsEmpty(request) || request.token === undefined || request.token === null) {
    if( this.validateFieldsEmpty(request) ) {
        this.alert.msgWarning('',this.msg.MSG_REQUIRED_FIELDS);
      return;
    } else {          
          this.loginService.getDataValidation(this.merchantId,this.projectId,request).
            subscribe(
              (data: any) => {
                sessionStorage.setItem('accountSession', JSON.stringify('user'));
                this.paymentDetail(this.merchantId,this.projectId);
              },(error) => {
                if("400" == error.status) {
                  this.alert.alertInfo(this.msg.MSG_NO_RESULTS_FOUND);
                } else if(0 == error.status) {
                  this.alert.alertError(this.msg.MSG_CONNECT_ERROR);
                }
          });
    }

}

  validateFieldsEmpty(request: DataValidationRequest) {
    let valida = 0;
    for( let i=0; i<request.data.length; i++) {
      if( '' == request.data[i].value ) {
        ++valida;
      }
    }

    if(valida > 0) {
      return true;
    } else {
      return false;
    }
  }

  paymentDetail(merchantId: string, projectId: string) {
    sessionStorage.setItem('dataLoginShared', JSON.stringify(this.dataLoginShared));
    sessionStorage.setItem('styleSite', JSON.stringify(this.styleSite));
    sessionStorage.setItem('logoPath', this.logoPath);
   return this.router.navigate(['/payment-detail']);
  }

}