
export class StyleSite {

  color: any;
  logoPath: any;
 
  constructor() {}

  getColor() {
    return this.color;
  }

  setColor(color: string){
    return this.color = {
      'background-color' : color
    }
  }

  getLogoPath() {
    return this.logoPath;
  }

   /* setLogoPath(logoPath: string){
    return this.logoPath =  { 
              'height': '200px',
              'width': '380px',
              'background-repeat': 'no-repeat',
              'background-image': 'url("'+logoPath+'")'
            };

  } */


   

  
}